import {
  IonCol, IonContent,
  IonGrid, IonLoading,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText, useIonAlert,
  useIonViewWillEnter
} from '@ionic/react'
import Header from '../../../components/Header'
import { useTranslation } from 'react-i18next'
import {
  useCertificateQuery,
  type Certificate as GqlCertificate,
  useShareCertificateMutation,
  usePrintCertificateMutation
} from '../../../lib/apollo/types'
import Certificate from '../../../components/Certificate'
import { type RouteComponentProps } from 'react-router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Button from '../../../components/Button'
import Buttons from '../../../components/Buttons'
import { checkmarkDone, copy, shareSocial, print } from 'ionicons/icons'
import JoinJMTModal from './JoinJMTModal'
import Modal from '../../../components/Modal'
import { CLUB_SHORT, FE_URL, GRAPHQL_URL, SCHOOL_IMAGE, TRANSLATION_NS } from '../../../lib/apollo/config'
import { useUser } from '../../../providers/Auth/hooks'

interface CertificateCampPageProps
  extends RouteComponentProps<{
    campId: string
  }> {
}

const API_URL = GRAPHQL_URL.replace('/graphql', '')

const base64UrlEncode = (input: string) => {
  return btoa(input)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

const CertificateCampPage: React.FC<CertificateCampPageProps> = ({ match }) => {
  const { t } = useTranslation()
  const { t: clubT } = useTranslation(TRANSLATION_NS)
  const { data } = useCertificateQuery()
  const [present] = useIonAlert()
  useIonViewWillEnter(() => {
    document.body.classList.add('cert')
  })
  const user = useUser()
  const email = user?.user.email

  const [shareMutation, { data: shareKey, loading }] = useShareCertificateMutation()

  const certificates = useMemo(() => data?.certificate.filter(certificate => certificate.camp.id === match.params.campId),
    [data?.certificate, match.params.campId])

  const [cert, setCert] = useState<GqlCertificate | null>()

  const [open, setOpen] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [buttonText, setButtonText] = useState('cert.copyShareLink')

  const [printMutation, { data: printData, loading: printLoading }] = usePrintCertificateMutation()

  const handlePrintClick = async () => {
    if (!cert) return

    // Show confirmation alert before proceeding
    present({
      header: 'Druckservice',
      message: 'Für die Erstellung deines Druckauftrags leiten wir dich jetzt an unseren Partner Frischmann Druck und Medien weiter. Die Nutzung dieses Services ist bis zum finalen Druckauftrag kostenlos. Erst wenn du am Ende überzeugt bist und deinen Druckauftrag abschickst, fallen Kosten an!',
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel'
        },
        {
          text: 'Jetzt loslegen',
          handler: async () => {
            try {
              const result = await printMutation({
                variables: { participantId: cert.participantId }
              })

              if (result?.data?.printCertificate) {
                const { printKeyOne, printKeyTwo } = result.data?.printCertificate as { printKeyOne: string, printKeyTwo: string }
                console.log(printData)

                const printDataJson = {
                  preview: `${API_URL}/static/certificates/${printKeyTwo}.jpg`,
                  image: `${API_URL}/static/certificates/${printKeyOne}.jpg`,
                  user: email
                }
                const jsonString = JSON.stringify(printDataJson)
                const encodedHash = base64UrlEncode(jsonString)

                const psk = 'zU30tl6RVQ3J9Uc2XVEDotbgTfnvzEDx'
                const printUrl = `https://joinmytalent.print-companion.de/quereinstieg/?psk=${psk}&hash=${encodedHash}`

                callWebhook()

                window.location.href = printUrl
              }
            } catch (err) {
              console.error('Error printing certificate:', err)
            }
          }
        }
      ]
    })
  }

  const callWebhook = async () => {
    const webhookUrl = 'https://n8n.betalabor.de/webhook/6ea4939f-1ec8-4430-9668-e2913fea6251'

    const dataPayload = {
      message: 'Redirecting to print service',
      email
    }

    try {
      await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataPayload)
      })
    } catch (error: any) {
      console.error('Error calling webhook:', error.message || error)
    }
  }

  const onShareClick = useCallback(async () => {
    if (!cert) return

    present({
      header: t('cert.share.hint.title'),
      message: t('cert.share.hint.message'),
      buttons: [
        {
          text: t('buttons.cancel'),
          role: 'cancel'
        },
        {
          text: t('buttons.ok'),
          handler: async () => {
            const { data } = await shareMutation({
              variables: {
                participantId: cert.participantId
              }
            })
            if (data?.shareCertificate) {
              setShareDialogOpen(true)
            }
          }
        }
      ]
    })
  }, [cert, present, shareMutation, t])

  useEffect(() => {
    setCert(certificates?.[0] ?? null)
  }, [certificates])

  return (
    <IonPage className='certificate-page camp cert-page'>
      <Header title={t('title.certificates')} />
      <IonContent className='camp-wrapper'>
        <IonLoading isOpen={loading} message={t('cert.share.loading') as string} />
        <IonLoading isOpen={printLoading} message={t('Einen Moment Bitte. Deine Druckdateien werden erstellt') as string} />
        <div className='certificates'>
          {(data?.certificate ?? []).length === 0 && (
            <div className='n2br'>
              <IonText
                className='ion-margin-bottom'
                color='medium'
              >
                <span>
                  {clubT('certificate.noCertificate')}
                </span>
              </IonText>
            </div>
          )}
          {cert && (certificates?.length ?? 0) > 0 && (
            <IonSegment color='primary' className='ion-padding-bottom' value={cert?.participantId} mode='md'>
              {certificates?.map(certificate => (
                <IonSegmentButton
                  value={certificate.participantId}
                  key={certificate.id}
                  onClick={() => { setCert(certificate) }}
                >
                  {certificate.caption}
                </IonSegmentButton>
              ))}
            </IonSegment>
          )}
          {cert && (
            <>
              <Certificate
                club={CLUB_SHORT}
                key={cert.camp.id}
                certificate={cert}
                header={{
                  intro: 'Bei uns bist du der Star'
                }}
                footer={{
                  text: clubT('certificate.footer'),
                  img: '/assets/football-badge-svgrepo-com.svg'
                }}
                clubImg='/assets/icon/logo.svg'
                schoolImg={SCHOOL_IMAGE}
              />
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <h1>{clubT('cert.shareWithJoinMyTalent.title')}</h1>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className='n2br'>
                    <IonText
                      color='medium'>{clubT('cert.shareWithJoinMyTalent.subtitle', { name: cert.caption })}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className='ion-padding-bottom ion-margin-bottom'>
                    <Buttons>
                      <Button
                        iconSrc='/assets/logo_jmt.svg'
                        color='jmt'
                        onClick={() => {
                          if (cert?.talentId && cert.talentId !== 'syncing') {
                            present({
                              message: t('cert.alreadyJoinedJMT'),
                              buttons: [
                                { text: t('buttons.ok') }
                              ]
                            })
                            return
                          }
                          setOpen(true)
                        }}
                      >
                        {clubT('cert.transferToJMT')}
                      </Button>
                    </Buttons>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-margin-top ion-padding-top ion-padding-bottom ion-margin-bottom'>
                  <IonRow>
                    <IonCol>
                      <h3>{t('cert.share.title')}</h3>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className='n2br'>
                      <IonText color='medium'>{clubT('cert.share.description')}</IonText>
                    </IonCol>
                  </IonRow>
                  <Buttons>
                    <Button
                      onClick={onShareClick}
                      icon={shareSocial}
                    >
                      {t('cert.share.button')}
                    </Button>
                  </Buttons>
                </IonRow>
                <IonRow className='ion-margin-top ion-padding-top ion-padding-bottom ion-margin-bottom'>
                  <IonRow>
                    <IonCol>
                      <h3>Perfekt präsentiert, professionell gedruckt!</h3>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="n2br">
                      <IonText color="medium">
                        <p>
                          Gib deinem Erfolg den Platz, den er verdient! Mit unserer
                          professionellen Drucklösung kannst du deinen Talentreport hochwertig
                          ausdrucken lassen und stilvoll an die Wand hängen. Zeig, worauf du stolz
                          bist!
                        </p>

                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                          <li>✔ Premium-Qualität: Hochauflösender Druck mit erstklassigen Materialien.</li>
                          <li>✔ Elegante Rahmen: Finde das Design, das deinen Erfolg unterstreicht.</li>
                          <li>✔ Einfache Bestellung: Wähle deine Optionen, und wir erledigen den Rest!</li>
                        </ul>

                        <p>Bereit, deinen Talentreport zu veredeln? Hier geht es weiter!</p>

                      </IonText>
                    </IonCol>

                  </IonRow>
                  <Buttons>
                    <Button
                      onClick={handlePrintClick}
                      icon={print}
                    >
                      Weiter
                    </Button>
                  </Buttons>
                </IonRow>
              </IonGrid>
              <JoinJMTModal open={open} close={() => { setOpen(false) }} certificate={cert} />
              <Modal
                size='alert'
                isOpen={shareDialogOpen}
                modalTitle={t('cert.shared')}
                onClose={() => { setShareDialogOpen(false) }}
                onDidDismiss={() => { setShareDialogOpen(false) }}
              >
                <Buttons
                  title={clubT('cert.share.title')}
                  subtitle={clubT('cert.share.subtitle')}
                >
                  <Button
                    icon={buttonText === 'cert.shareLinkCopied' ? checkmarkDone : copy}
                    onClick={async () => {
                      await navigator.clipboard.writeText(
                        `${FE_URL}s/${shareKey?.shareCertificate}`
                      )
                      setButtonText('cert.shareLinkCopied')
                    }}
                  >
                    {t(buttonText)}
                  </Button>
                </Buttons>
              </Modal>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  )
}

export default CertificateCampPage
