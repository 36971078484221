import './style.scss'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import clsx from 'clsx'
import { TRANSLATION_NS } from '../../lib/apollo/config'

interface TopSkillsProps {
  talentClass: number
  amount?: number
  values?: number[] // overrides publicrating
  compareValues?: number[]
  oldTitle?: string
  newTitle?: string
  className?: string
  asCard?: boolean
  withHeadline?: boolean
  withValues?: boolean
}

const TopSkills: React.FC<TopSkillsProps> = ({
  className,
  talentClass,
  amount = 4,
  values: v,
  withValues
}) => {
  const { t } = useTranslation(TRANSLATION_NS)

  // map array of numbers to sorted index
  const values = useMemo(() => v
    ?.map((value, index) => ({ value, index }))
    .sort((a, b) => b.value - a.value)
    .slice(0, amount)
  , [v, amount])

  const position = talentClass === 2 ? 'goalie.' : ''

  if (!values) return null

  return (
        <>
            <div
                className={clsx(className, 'talent-top-skills')}
            >
                <div
                    className='talent-top-skills__row'
                >
                    {values.map(({ index, value }) => {
                      return (
                            <div
                                key={index}
                                className={clsx('talent-top-skills__skill-wrapper')}
                            >
                                <img
                                    className='talent-top-skills__img'
                                    alt='skill-indicator'
                                    src='/assets/icon/logo.svg'
                                />

                                <h4
                                    className='talent-top-skills__skill ion-text-left'
                                >
                                    {t(`i18n.vote.${position}${index}`)}{withValues ? ` . ${value}` : ''}
                                </h4>
                            </div>
                      )
                    })}
                </div>
            </div>
        </>
  )
}

export default TopSkills
