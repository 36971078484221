import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'
import de from './de.json'
import en from './en.json'
import zh from './zh.json'
import { TRANSLATION_FALLBACK, TRANSLATION_NS, TRANSLATION_URL } from '../apollo/config'

const languageDetector = new LanguageDetector(null, {
  convertDetectedLanguage: (lng) => {
    if (lng === 'de-DE') {
      return 'de'
    } else {
      return lng
    }
  }
})

await i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(ChainedBackend)
  .use(languageDetector)
  .init({
    fallbackLng: TRANSLATION_FALLBACK,
    ns: ['default', TRANSLATION_NS],
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend({ de: { translation: de }, en: { translation: en }, zh: { translation: zh } })
      ],
      backendOptions: [{
        crossDomain: true,
        loadPath: `${TRANSLATION_URL}/{ns}/{lng}.json`
      }]
    },
    // saveMissing: !!API_TOKEN,
    interpolation: {
      escapeValue: false, // react already safes from xss
      prefix: '{',
      suffix: '}'
    },
    react: {
      useSuspense: false
    },
    returnNull: false,
    returnEmptyString: false
  })

export default i18n
