// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const env = { ...process.env, ...window.env }

export const GRAPHQL_URL = env.REACT_APP_GRAPHQL_URL as string ?? 'http://localhost:3001/graphql'
export const FE_URL = env.REACT_APP_FE_URL as string ?? 'http://localhost:3001/'
export const IS_PROD = env.REACT_APP_IS_PROD as string === 'true'
export const ENV_NAME = env.REACT_APP_ENV_NAME as string ?? 'dev'
export const TRANSLATION_API_TOKEN = env.REACT_APP_TRANSLATION_API_TOKEN as string
export const TRANSLATION_URL = env.REACT_APP_TRANSLATION_URL as string ?? 'no url set'

export const CLUB_SHORT = env.REACT_APP_CLUB_SHORT as string === 'demo' ? 'default' : env.REACT_APP_CLUB_SHORT as string ?? 'default'
export const TRANSLATION_NS = env.REACT_APP_TRANSLATION_NS as string ?? CLUB_SHORT
export const TRANSLATION_FALLBACK = env.REACT_APP_TRANSLATION_FALLBACK as string ?? 'de'
export const SCHOOL_IMAGE = env.REACT_APP_SCHOOL_IMAGE as string ?? '/assets/football-badge-svgrepo-com.svg'
export const CLUB_IMAGE = env.REACT_APP_CLUB_IMAGE as string ?? '/assets/icon/logo.svg'
export const CARD_HEADER = env.REACT_APP_CARD_HEADER as string | undefined
export const CERTIFICATE_CHANGE_DATE = env.CERTIFICATE_CHANGE_DATE as string | undefined

export const SSO_ACTIVE = env.REACT_APP_SSO_ACTIVE as boolean ?? false
export const SSO_URL = env.REACT_APP_SSO_URL as string ?? undefined
export const SSO_REDIRECT_URL = env.REACT_APP_SSO_REDIRECT_URL as string ?? undefined
export const SSO_CLIENTID = env.REACT_APP_SSO_CLIENTID as string ?? undefined
export const SSO_LOGOUT_URL = env.REACT_APP_SSO_LOGOUT_URL as string ?? undefined

export const DISPOSITIONS_ACTIVE = env.REACT_APP_DISPOSITIONS_ACTIVE as boolean ?? false
